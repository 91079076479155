







import Vue from 'vue'

import GlobalHeader from './components/GlobalHeader.vue'

export default Vue.extend({
    data() {
        return {
            
        }
    },
    computed: {
        cssVariables: function() {
            if(this.$store.state.temporaryConfig) {
                return {
                    '--color-bg': this.$store.state.temporaryConfig.colors.bg,
                    '--color-mid': this.$store.state.temporaryConfig.colors.mid,
                    '--color-fg': this.$store.state.temporaryConfig.colors.fg,
                    '--font-mono': this.$store.state.temporaryConfig.fonts.mono,
                }
            } else {
                return {
                    '--color-bg': this.$store.state.config.colors.bg,
                    '--color-mid': this.$store.state.config.colors.mid,
                    '--color-fg': this.$store.state.config.colors.fg,
                    '--font-mono': this.$store.state.config.fonts.mono,
                }
            }

        }
    },
    components: {
        GlobalHeader
    },
    created: function() {
        if(this.$store.state.config.useColoredFavicon) {
            window['setFavicon'](this.$store.state.config.colors.bg, this.$store.state.config.colors.fg)
        }
    }
})
