

































import Vue from 'vue'
import { Size } from '../models/size'
import { Suit } from '../models/card'
import Icon from '../components/Icon.vue'

export default Vue.extend({
    props: {
        card: Object,
        reversed: Boolean,
        size: {
            type: Number,
            default: 1
        },
    },
    data() {
        return {
            isReversed: this.reversed,

            Size
        }
    },
    computed: {
        suitSymbol: function() {
            return ['swords', 'wands', 'cups', 'pentacles'][this.card.suit - 1]
        },
        numberSymbol: function() {
            return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'P', 'N', 'Q', 'K'][this.card.number - 1]
        },
        majorArcanaRomanNumeral: function() {
            return this.$store.getters.romanNumeral(this.card.number)
        },
        sizeValue: function() {
            return this.size as Size
        }
    },
    methods: {
        reverse: function() {
            this.$emit('reverse')
        }
    },
    created: function() {

    },
    components: {
        Icon,
    },
})
