
































































































    import Vue from 'vue'
    import Fuse from 'fuse.js'

    import TarotCard from '../components/TarotCard.vue'
    import Icon from '../components/Icon.vue'

    import { Card, Suit } from '../models/card'
    import { Size } from '../models/size'

    export default Vue.extend({
        data() {
            return {
                searchText: '',
                card: null as Card,
                isReversed: false,
                suggestions: [],

                size: Size.Large as Size,
            }
        },
        methods: {
            searchTextChanged: function(event) {
                const options = {
                    ignoreLocation: true,
                    useExtendedSearch: true,
                    keys: [
                        'searchKey'
                    ]
                }

                const fuse = new Fuse(this.$store.state.cards, options)

                this.suggestions = fuse.search(this.searchText)

                if(this.suggestions.length) {
                    this.card = this.suggestions[0].item
                }
            },
            reverse: function() {
                this.isReversed = !this.isReversed
            },
            scrollToNextPage(event) {
                let next = event.target.closest('.meanings__page').nextElementSibling

                if(next) {
                    next.scrollIntoView({
                        block: 'start',
                        behavior: 'smooth',
                    })
                }
            },
            updateTitle() {
                if(this.card) {
                    window.history.replaceState({}, document.title, '/#/meanings/' + this.card.id + (this.isReversed ? '-r' : ''))
                    document.title = this.$store.getters.pageTitle(this.card.name + (this.isReversed ? ' (reversed)' : ''))
                }
            },
            cleanURL(urlString) {
                let url = new URL(urlString)

                let host = url.host.replace(/^www\./, '')

                return host
            }
        },
        created: function() {
            let params = this.$route.params

            if(params.id) {
                let parts = params.id.split('-')

                let first = parts[0].toLowerCase()
                let card = this.$store.state.cards.find(card => card.id == first)

                if(card) {
                    this.card = card
                }
                
                if(parts.length > 1 && parts[1] == 'r') {
                    this.isReversed = true
                }
            }
        },
        watch: {
            card: function(current, previous) {
                if(current) {
                   this.updateTitle()
                }
            },
            isReversed: function(current, previous) {
                this.updateTitle()
            }
        },
        computed: {
            meanings: function() {
                return this.card.meanings[this.isReversed ? 'reversed' : 'upright']
            },
			kabalaLink: function() {
                let name = this.card.name

                name = name.replace(/^[Tt]he\s*/, '')

                let slug = name.toLowerCase().split(/\s+/).join('-')

                return `https://hoc.kabala.vn/${slug}/`

            },
            labyrinthosLink: function() {
                let name = this.card.name
                if(name == 'Wheel of Fortune') name = 'The ' + name // labyrinthos link for WoF has 'The'

                let slug = name.toLowerCase().split(/\s+/).join('-')

                return `https://labyrinthos.co/blogs/tarot-card-meanings-list/${slug}-meaning-${this.card.isMajorArcana() ? 'major-arcana-' : ''}tarot-card-meanings`
            },
            biddyLink: function() {
                let name = this.card.name

                name = name.replace(/^[Tt]he\s*/, '')

                let slug = name.toLowerCase().split(/\s+/).join('-')

                if(this.card.isMajorArcana()) {
                    return `https://www.biddytarot.com/tarot-card-meanings/major-arcana/${slug}/`
                } else {
                    return `https://www.biddytarot.com/tarot-card-meanings/minor-arcana/suit-of-${this.card.suitName().toLowerCase()}/${slug}/`
                }

            },
            busterBensonLink: function() {
                let suit = this.card.suitName().toLowerCase()
                if(this.card.suit == Suit.Pentacles) {
                    suit = 'coins'
                }

                let slug = `${this.card.isMajorArcana() ? 'major' : suit }-${this.card.number.toString().padStart(2, '0')}`

                return `https://notes.busterbenson.com/tarot/${slug}`
            }
        },
        components: {
            TarotCard,
            Icon,
        }
    })
