






















import Vue from 'vue'
import SpreadItem from '../components/SpreadItem.vue'

import { Spread, SpreadCard } from '../models/spread'

export default Vue.extend({
    data() {
        return {
            
        }
    },
    computed: {
        groups: function() {
            let groups = {}

            for(let spread of this.$store.state.spreads) {
                let groupName = spread.group || spread.cards.length

                if(!groups[groupName]) {
                    groups[groupName] = {
                        name: spread.group || `${spread.cards.length} card${spread.cards.length > 1 ? 's' : ''}`,
                        spreads: [],
                    }
                }

                groups[groupName].spreads.push(spread)

            }

            return groups
        }
    },
    methods: {

    },
    components: {
        SpreadItem,
    }
})
