export default [
    { name: 'The Fool', colors: { bg: '#2d678b', mid: '#7dc4e3', fg: '#f6b93b' }},
    { name: 'The Magician', colors: { bg: '#60395f', mid: '#c2f6ff', fg: '#ff85cc' }},
    { name: 'The High Priestess', colors: { bg: '#abc176', mid: '#b92da8', fg: '#000000' }},
    { name: 'The Empress', colors: { bg: '#2c1d2f', mid: '#f6b93b', fg: '#ffffff' }},
    { name: 'The Emperor', colors: { bg: '#ffffff', mid: '#ffae00', fg: '#9e16d0' }},
    { name: 'The Hierophant', colors: { bg: '#151b23', mid: '#ffc800', fg: '#ffffff' }},
    { name: 'The Lovers', colors: { bg: '#2f090f', mid: '#dd97ce', fg: '#ff0095' }},
    { name: 'The Chariot', colors: { bg: '#e1a056', mid: '#cd3c18', fg: '#42546c' }},
    { name: 'Strength', colors: { bg: '#000000', mid: '#d82234', fg: '#ffffff' }},
    { name: 'The Hermit', colors: { bg: '#326c5b', mid: '#52dcff', fg: '#ecc8a7' }},
    { name: 'Wheel of Fortune', colors: { bg: '#ccf9ff', mid: '#e68d28', fg: '#2b5a97' }},
    { name: 'Justice', colors: { bg: '#fafafa', mid: '#b0b0b0', fg: '#545454' }},
    { name: 'The Hanged Man', colors: { bg: '#080808', mid: '#616161', fg: '#d6d6d6' }},
    { name: 'Death', colors: { bg: '#c52020', mid: '#ffffff', fg: '#ffffff' }},
    { name: 'Temperance', colors: { bg: '#f9f5e1', mid: '#40be27', fg: '#684e22' }},
    { name: 'The Devil', colors: { bg: '#ffd500', mid: '#ff0000', fg: '#000000' }},
    { name: 'The Tower', colors: { bg: '#104221', mid: '#fbff00', fg: '#00ffb7' }},
    { name: 'The Star', colors: { bg: '#231f1f', mid: '#9f332d', fg: '#eeafa0' }},
    { name: 'The Moon', colors: { bg: '#070709', mid: '#618198', fg: '#2bb5e3' }},
    { name: 'Temperance', colors: { bg: '#d4ede2', mid: '#db7629', fg: '#493218' }},
    { name: 'The World', colors: { bg: '#f8f8f8', mid: '#6ecd0e', fg: '#f08a8a' }},
]