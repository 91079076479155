













import Vue from 'vue'
import { Spread } from '../models/spread'

export default Vue.extend({
    props: {
        spread: Spread,
    },
    created: function() {
        
    }
})
